import React from 'react';
import Accordion from '../common/Accordion/Accordion';

import { getMessages } from '../../locales';

const FAQSection = () => {
  const messages = getMessages();

  return (
    <div className="home-faq container">
      <h3 className="title-lg">{messages['faqSection.title']}</h3>
      {messages['faqSection.questions'].map(({ title, answer }, id) => {
        const accordionTitle = `<strong>0${id + 1}</strong> ${title}`;
        return <Accordion key={id} title={accordionTitle} answer={answer} />
      })}
    </div>
  );
};

export default FAQSection;
