import React from 'react';
import parse from 'html-react-parser';

import imageUrl from '../../assets/home/who-we-are-img.svg';
import Button from '../common/Button/Button';

import { getMessages } from '../../locales';

const WhoWeAre = () => {
  const messages = getMessages();

  return (
    <section className="who-we-are container">
      <div className="row align-items-center">
        <div className="who-we-are-image col-sm-12 col-md-12 col-lg-6">
          <img width="714" height="683" src={imageUrl} alt={messages['whoWeAre.imageAlt']} />
        </div>
        <div className="who-we-are-desc col-sm-12 col-md-12 col-lg-6">
          <h2 className="title-lg">{messages['whoWeAre.title']}</h2>
          <p className="subtitle">{parse(messages['whoWeAre.desc'])}</p>
          <Button
            buttonStyle="redesigned-primary"
            linkTo="/about"
            title={messages['whoWeAre.button']}
          />
        </div>
      </div>
    </section>
  );
};

export default WhoWeAre;
